<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

                <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>

					<h3>İzin</h3>

					<p class='text-lg'>
                       İzin başlangıç, bitiş, izin tipi ve açıklama kısımları zorunludur.<br>
                       <br>
                       Personel izinlerinde dikkat etmeniz en önemli kısım ücretli veya ücretsiz izin durumudur.<br>
                       <br>
                       Personelinize ücretsiz izin vermeyi uygun gördüğünüz durumlarda avans durumunu kontrol etmeniz gerekmektedir. Avans ve ücretsiz izin miktarının toplamı maaşı geçerse ay sonu maaş hesaplamasında eksi (-) maaş hesaplanır.<br>
                       <br>
                       Ücretsiz izinlerde sistem personel maaşını 30 güne bölüp günlük ücreti eksi (-) olarak ay sonundaki maaş hesaplamasında resmi ve diğer maaşdan orantılı şekilde düşer.<br>
                       <br>
                       İzin açıklmasını doldurmanız muhasebe olarak iznin resmileştirmesine yardımcı olacaktır.<br>
                       <br>
                       Hangi durumlarda ücretli veya ücretsiz izin verildiğini muhasebenize danışarak tanımlayabilirsiniz.<br>
                       <br>
                       Maaş hesabı yapılmış ise tanımlanmış izinleri silemezsiniz. İzin durumu iptal edildi ise maaş hesaplama yapmadan izni siliniz.
					</p>

				</Sidebar>

				</span>
                <span class='mt-5'>
                    <span
                        v-if='annualLeave.dayCount > 0'>Seçmiş olduğunuz tarihler arası toplamı  {{ annualLeave.dayCount
                        }} iş günü içermektedir.<br><br></span>
               
                    <span v-if='annualLeave.totalPrice > 0 && annualLeave.isPriceCut'>Personelinizden ücretsiz izin seçildiği için toplam net maaşından {{ this.annualLeave.totalPrice
                        }} &#8378; düşürülecektir.<br><br></span>
               
                    <span v-if='!annualLeave.isPriceCut && annualLeave.dayCount > 0'>Personelinizden ücretli izin seçildiği bir kesinti yapılmayacaktır.<br><br></span>
                </span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId'
                                           :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İzin Tipi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='type' v-model='annualLeave.type' :options='typeList' optionLabel='label'
                                      option-value='value' placeholder='Seçim Yapın' @change='dateSelected'></Dropdown>
                        </div>
                    </div>
                    <div class='field grid mb-5' v-if='annualLeaveTypeSelected'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İzin Başlangıç Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='startDate' dateFormat='dd.mm.yy' autocomplete='off' :showIcon='true'
                                      :showButtonBar='true' v-model='annualLeave.startDate'
                                      @date-select='dateSelected'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='annualLeaveTypeSelected'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İşe Başlangıç Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='endDate' dateFormat='dd.mm.yy' autocomplete='off' :showIcon='true'
                                      :showButtonBar='true' v-model='annualLeave.endDate'
                                      @date-select='dateSelected'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İzin Gün Sayısı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='dayCount' autocomplete='off' v-model='annualLeave.dayCount' showButtons
                                       readonly='readonly'></InputText>
                        </div>
                    </div>



                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Ücret Kesilecek Mi?</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isPriceCut' v-model='annualLeave.isPriceCut' @change='dateSelected' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Açıklama<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='comment' type='text' autocomplete='off' v-model='annualLeave.comment' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                    <div class='mt-5'>

                        <Toolbar class='mb-3 p-3'>
                            <template v-slot:start>
                                <div class='my-0'>
                                    <h5 class='mb-0'>
                                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true'
                                                  :showButtonBar='true' v-model='selectedSalaryDate'
                                                  @date-select='salaryDateChanged'></Calendar>
                                    </h5>
                                </div>
                            </template>
                        </Toolbar>

                        <DataTable :value='dayOffList' class='p-datatable-gridlines' :rows='10' dataKey='id'
                                   :rowHover='true' :paginator='true' :loading='loadingIndicator'
                                   responsiveLayout='scroll'>

                            <template #empty>
                                Veri bulunamadı.
                            </template>

                            <template #loading>
                                Projedeki Mesailer yükleniyor. Lütfen bekleyin.
                            </template>

                            <Column field='startDate' header='Başlangıç Tarihi' style='width:8rem'>
                                <template #body='slotProps'>
                                    {{ formatDate(slotProps.data.startDate) }}
                                </template>
                            </Column>

                            <Column header='Bitiş Tarihi' style='width:8rem'>
                                <template #body='slotProps'>
                                    {{ formatDate(slotProps.data.endDate) }}
                                </template>
                            </Column>

                            <Column field='dayCount' header='Gün Sayısı' style='width:8rem'>
                            </Column>

                            <Column field='totalPrice' header='Kesilen Ücret' style='width:8rem'>
                                <template #body='slotProps'>
                                    {{ formatPrice(slotProps.data.totalPrice) }} &#8378;
                                </template>
                            </Column>

                            <Column field='type' header='İzin Tipi' style='width:8rem'>

                            </Column>

                            <Column header='Ücret Kesildi Mi?' style='width:8rem'>
                                <template #body='slotProps'>
                                    {{ slotProps.data.isPriceCut == true ? 'Evet' : 'Hayır' }}
                                </template>
                            </Column>

                            <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-trash' class='p-button-rounded p-button-primary'
                                            @click='deleteDayOff(slotProps.data)' />
                                </template>
                            </Column>

                        </DataTable>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import EmployeeDayOffService from '../../services/employeeDayOffService';
import {
    checkEmployeeSalaryCalculated, checkEmployeeSalaryChanged,
    checkEmployeeStillActive,
    getEmployee,
    getProjectEmployee,
} from '../common/commonFunctions';
import {
    checkActiveProjectIdIsValid,
    getActiveProjectId,
    getUserId,
    normalizeDate,
} from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _employeeDayOffService: null,
    _smsService:null,
    computed:{
        annualLeaveTypeSelected() {
            return this.annualLeave.type != ""
        }
    },

    created() {
        this._smsService = new SmsService();
        this.selectedSalaryDate = moment().toDate();
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
        this._employeeService = new EmployeeService();
        this._employeeDayOffService = new EmployeeDayOffService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();
        this._projectEmployeeService = new ProjectEmployeeService();
    },

    async mounted() {
        this.employee = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.getEmployeeDayOffList();
    },
    data() {

        return {
            isProcessing: false,
            annualLeave: {
                startDate: null,
                endDate: null,
                dayCount: 0,
                isPriceCut: false,
                type: '',
                comment: '',
                totalPrice: 0,
            },
            year: 1900,
            month: 1,
            selectedSalaryDate: null,
            loadingIndicator: false,
            projectEmployeeId: 0,
            employeeId: 0,
            dayOffList: [],
            projectEmployee: {
                officialSalary: 0,
                unOfficialSalary: 0,
            },
            employee: {},
            typeList: [
                {
                    label: 'Yıllık Ücretli İzin',
                    value: 'Yıllık Ücretli İzin',
                },
                {
                    label: 'Ücretsiz İzin',
                    value: 'Ücretsiz İzin',
                },
                {
                    label: 'Sağlık Raporlu',
                    value: 'Sağlık Raporlu',
                },
                {
                    label: 'Mazeret İzni',
                    value: 'Mazeret İzni',
                },
            ],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async dateSelected() {

            if (this.annualLeave.startDate == null || this.annualLeave.endDate == null) {
                this.annualLeave.dayCount = 0;
                this.annualLeave.totalPrice = 0;
                return;
            }
            if ((await this.checkDaysAvailable()) == false) {
                this.annualLeave.dayCount = 0;
                this.annualLeave.totalPrice = 0;
                return;
            }

            await this.buildDayOffCreateObject();
        },
        async buildDayOffCreateObject() {
            this.annualLeave.startDate = normalizeDate(this.annualLeave.startDate);
            this.annualLeave.endDate = normalizeDate(this.annualLeave.endDate);
            let dayCount = await this._employeeDayOffService.getDayCount(this.annualLeave.startDate, this.annualLeave.endDate, this.annualLeave.type);
            this.annualLeave.dayCount = dayCount;
            let dailyPrice = (Number((this.projectEmployee.officialSalary / 30))) + (Number((this.projectEmployee.unOfficialSalary / 30)));
            if (this.annualLeave.isPriceCut == false) {
                this.annualLeave.totalPrice = 0;
            } else {
                this.annualLeave.totalPrice = (dailyPrice * this.annualLeave.dayCount).toFixed(0);
            }

            this.annualLeave.projectId = this.projectEmployee.projectId;
            this.annualLeave.employeeId = this.employeeId;
            this.annualLeave.isActive = true;
            this.annualLeave.projectEmployeeId = this.projectEmployeeId;
            this.annualLeave.managerUserId = getUserId();
        },
        resetForm() {
            this.annualLeave.projectId = this.projectEmployee.projectId;
            this.annualLeave.employeeId = this.employeeId;
            this.annualLeave.startDate = null;
            this.annualLeave.endDate = null;
            this.annualLeave.dayCount = 0;
            this.annualLeave.type = '';
            this.annualLeave.comment = '';
            this.annualLeave.totalPrice = 0;
            this.annualLeave.isPriceCut = false;
            this.annualLeave.projectEmployeeId = 0;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {
                await this.buildDayOffCreateObject();
                const insertResponse = await this._employeeDayOffService.insertEmployeeDayOff(this.annualLeave);
                if (insertResponse.isSuccess) {
                    await this.getEmployeeDayOffList();
                    await this._smsService.sendEmployeeNewDayOffMessage(this.projectEmployeeId, insertResponse.data);
                    this.resetForm();
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async validateForm() {

            if (!checkEmployeeSalaryChanged(this, this.projectEmployee)) {
                return false;
            }

            if (await checkEmployeeStillActive(this, this.projectEmployeeId) == false) {
                return false;
            }

            if (this.annualLeave.startDate == '' || this.annualLeave.startDate == null) {
                showValidationMessage(this, 'İzin Başlangıç Tarihi Girilmelidir');
                return false;
            }
            if (this.annualLeave.endDate == '' || this.annualLeave.endDate == null) {
                showValidationMessage(this, 'İzin Bitiş Tarihini Girilmelidir');
                return false;
            }

            if (this.annualLeave.type == '') {
                showValidationMessage(this, 'İzin Tipini Seçilmelidir.');
                return false;
            }

            if (this.annualLeave.comment == '') {
                showValidationMessage(this, 'İzin açıklaması dolu olmalı');
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, this.annualLeave.startDate, this.projectEmployeeId) == true) {
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, this.annualLeave.endDate, this.projectEmployeeId) == true) {
                showValidationMessage(this, 'Bu Çalışan İçin Maaş Hesaplaması Yapılmış');
                return false;
            }

            if (this.annualLeave.dayCount == '' || this.annualLeave.dayCount == 0) {
                showValidationMessage(this, 'İzin Günleri Aralığı Girilmelidir');
                return false;
            }


            return true;
        },

        async checkDaysAvailable() {
            let checkResponse = await this._employeeDayOffService.checkDayOffDates(this.projectEmployeeId, this.annualLeave.startDate, this.annualLeave.endDate);
            if (checkResponse == true) {
                showValidationMessage(this, 'Bu Tarihlerde Zaten Bir İzin Tanımlı');
                return false;
            }
            return true;
        },

        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            await this.getEmployeeDayOffList();
        },

        async getEmployeeDayOffList() {
            this.loadingIndicator = true;
            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let dayOffResponse = await this._employeeDayOffService.getEmployeeDayOffs(this.projectEmployeeId, startDate, endDate);
            if (dayOffResponse.isSuccess) {
                this.dayOffList = dayOffResponse.data;
            }
            this.loadingIndicator = false;
        },

        async deleteDayOff(item) {
            if (await checkEmployeeSalaryCalculated(this, item.startDate, this.projectEmployeeId) == true) {
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, item.endDate, this.projectEmployeeId) == true) {
                showValidationMessage(this, 'Bu Çalışan İçin Maaş Hesaplaması Yapılmış.');
                return false;
            }

            this.$confirm.require({
                message: 'İzni Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let deleteResponse = await this._employeeDayOffService.deleteEmployeeDayOff(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getEmployeeDayOffList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                },
                reject: () => {
                },
                onShow: () => {
                },
                onHide: () => {
                },
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
    },
};

</script>


<style scoped>

</style>
